import React from 'react'
import UserCart from './userProfileDetails'
import ChooseInfluencer from './InfluencerList'
function Alluser() {
  return (
    <div>
        <UserCart/>
        <ChooseInfluencer/>
    </div>
  )
}

export default Alluser