import React from 'react'
import "../../../styles/brand/pricayPolicy/PrivacyPolicy.css"


function PrivacyPolicy() {
    return (
        <div>
            <div className="secfstpp">
                <h2>Privacy Policy</h2><br /><br />
                <p>
                    Privacy Policy explains how your information is collected and used when you register and use
                    the Tixteen platform (“Platform” or the “Service”), which is operated by Tixteen
                    (“Tixteen” or “we”, “us”, “our”).
                    <br />
                    Protecting and respecting data privacy is important to us. Please read this Privacy Policy
                    carefully.
                    <br />
                    In short, we collect data about a wide range of influencers around the world – that is
                    YouTube, Facebook,Linkedin,Twitter, and Instagram users who have become popular with a
                    sizable number of followers (5k) (“Influencer(s)”). Information about Influencers and their
                    online performance. We also want to inform our registered influencers, we have rights to use
                    your ID information, profile pic for Tixteen advertisement. This helps our Users analyse and
                    determine which Influencers they may want to engage based on their marketing needs and
                    business activities.
                </p>


                <h3>WHAT PERSONAL DATA WE PROCESS AND WHY</h3>
                <p>
                    <b>We collect and process User’s basic contact information to allow them to access and
                        use the Platform.</b>
                </p>
                <p>
                    We collect and process your Tixteen ID, email address and chosen password, to authenticate
                    User access to the Platform. We refer to this data as “Registration Information”.
                    The legal basis under law for collecting and processing your Registration Information is our
                    legitimate interest in operating and managing User access to the Platform.
                </p>

                <p>
                    <b>We collect and process information about each User’s data consumption on the
                        Platform to gauge data consumption.</b>
                </p>
                <p>
                    We collect and process metrics about the data that each User consumed on our Platform, to
                    gauge data consumption by the User.
                    The legal basis under law for collecting and processing your Registration Information is our
                    legitimate interest in determining business costs and profitability of the User.
                </p>

                <p>
                    <b>We collect information about Influencers, including aggregated statistics about
                        their social media activities and performance.</b>
                </p>
                <p>
                    We information about Influencers around the india such as their name (and social media
                    nickname if any), general location (country/state), social media profile image, gender,
                    personal characteristics, social media content posted by the Influencer (including hashtags
                    and brands advertised by the Influencer), social media performance data (e.g., number of
                    followers, engagement rate, likes over time, assessed follower-credibility and follower
                    demographics). We refer to this information as “Influencer Information”. We process this
                    information to help Users to search for desired Instagram,Facebook,Linkedin,Twitter, and
                    YouTube etc. Influencers across various search parameters.<br />
                    This original source of information is publicly available information generated by the
                    Influencer’s online activities on Instagram, Facebook,Linkedin,Twitter, and YouTube.
                    <br />
                    The legal basis under law for collecting and processing Influencer Information is our
                    legitimate interest in providing sophisticated influencer search capabilities that facilitate the
                    needs of Users to find social media Influencers suitable for their needs.
                </p>


                <h3>WITH WHOM WE SHARE YOUR DATA</h3>
                <p>
                    We will process information with our service providers helping us to operate our
                    business.</p>
                <p>
                    We will process personal information with the assistance of our service providers who
                    assist us with the internal operations of the Service, such as Google Cloud. These
                    companies are authorised to use personal information in this context only as
                    necessary to provide these services to us and not for their own purposes.
                    We will share Registration Information with competent authorities, if a User has
                    abused its right to use the Service, or violate any applicable law.</p>
                <p>
                    If a User has abused their rights to use the Service, or if violated any applicable law,
                    we will share Registration Information with competent authorities and with third
                    parties (such as legal counsels and advisors), for the purpose of handling the violation
                    or breach.</p>
                <p>
                    The legal basis under In. The law for processing personal information for the purpose
                    of handling instances of abusive use of the Service is our legitimate interest in
                    defending and enforcing against violations and breaches that are harmful to our
                    business.</p>
                <p>
                    We will share personal information if we are legally required to do so.
                    We will share any information we are required to by a judicial, governmental or
                    regulatory authority.</p>
                <p>
                    The legal basis under In law for processing personal information where we are legally
                    required to share it, is our legitimate interests in complying with mandatory legal
                    requirements imposed on us.</p>
                <p>
                    We will share personal information with third parties in any event of change in our
                    structure.</p>
                <p>
                    If the operation of our Service is organised within a different framework, or through
                    another legal structure or entity (such as due to a merger or acquisition), we will
                    share information only as required to enable the structural change in the operation of
                    the business.</p>
                <p>
                    The legal basis under In law for us sharing personal information in the event of a
                    change in our corporate structure is our legitimate interests in our business
                    continuity.
                </p>


                <br /><br />

                <h3>SECURITY AND DATA RETENTION</h3>
                <p>
                    We will retain Registration Information while the User is engaged with us. We do not
                    retain Influencer Information over time.
                </p>

                <p>
                    We will retain Registration Information for as long as the User is engaged under a
                    service agreement with us. Thereafter, we will still retain it as necessary to comply
                    with our legal obligations, resolve disputes, establish and defend legal claims and
                    enforce our agreements. Influencer Information is processed only instantaneously
                    when data is presented to our users through the Platform and is not retained by
                    Tixteen Pvt Ltd.
                </p>
                <p>
                    We implement measures to secure personal information.
                </p>
                <p>We implement measures to reduce the risks of damage, loss of information, and
                    unauthorised access or use of information, such as secured HTTP. However, these
                    measures do not provide absolute information security. Therefore, although efforts
                    are made to secure personal information, it is not guaranteed, and one cannot expect
                    that the Service be immune from information security risks.</p>
                <br /><br />

                <h3>ADDITIONAL INFORMATION FOR INDIVIDUALS IN THE INDIA</h3>
                <p>
                    Tixteen Pvt Ltd is the data controller of the personal information processed
                    by the Service.<br />
                    The following is the data controller for the purposes of the personal data we
                    collect via the Platform:<br />
                    Tixteen Pvt Ltd<br />
                    Scf-61, First Floor, Opp. CFC School, F Block, Bhai Randhir Singh Nagar,
                    Ludhiana, Punjab 141012<br />
                    Email: <a href="mailto:support@tixteen.com">support@tixteen.com</a>
                </p>

                <p>
                    If you wish to exercise any of these rights, contact us at <a href="mailto:support@tixteen.com">support@tixteen.com</a>
                </p>
                <br /><br />

                <h3>CHANGES TO THIS PRIVACY NOTICE</h3>
                <p>
                    If we change this Privacy Policy, we will make efforts to proactively notify you of such
                    changes.
                </p>
                <p>From time to time, we may change this Privacy Policy. If we do so, we will make
                    efforts to proactively notify you of such changes. In any event, the latest version of
                    the Privacy Policy will always be accessible at: https://tixteen.com/privacy-policy/</p>



            </div>

        </div>
    )
}

export default PrivacyPolicy