import React from 'react'
import Createdordeteils from './createdordeteils'
import MyAccounts from './MyAccounts'
import MyAccountheader from './myAccountheader'

function MaincreatorDetails() {
  return (
    <div className='main_all_page_default_padding mt-1' >
      <Createdordeteils />
      <MyAccounts />
    </div>
  )
}

export default MaincreatorDetails