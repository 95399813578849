import React from 'react'
function InfluencerFirstMainPage() {
    return (
        <div style={{ height: "100vh", backgroundColor: "#FBDD03" }} className='d-flex justify-content-center align-items-center'  >
            <div className="">
                <div className="Industries_text "
                    style={{ fontSize: "120px" }}
                >INFLUENCER </div>
                <div className="we_serve_text">Network Overview</div>
            </div>
        </div>
    )
}

export default InfluencerFirstMainPage