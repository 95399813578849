

import React from 'react'
import Mainindex from './mainIndex'

function Dashboard() {
  return (
    <div>
      <Mainindex/>
    </div>
  )
}

export default Dashboard